import React, { useEffect, useState } from "react";
import { Editor } from "@monaco-editor/react";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import Refresh from "@mui/icons-material/Refresh";
import { useSelector } from "react-redux";
import io from "socket.io-client";

const CodeEditor = ({
  userCode,
  setUserCode,
  originalCode,
  userCodeOutput,
  setUserCodeOutput,
  currentFileName,
}) => {
  const tutorState = useSelector((state) => state.tutor);
  const { pages, currentPageIndex, currentSubtopic } = tutorState;
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [input, setInput] = useState("");
  const [inputNeeded, setInputNeeded] = useState(false);
  const [currentPageProgrammingLanguage, setCurrentPageProgrammingLanguage] =
    useState("");
  const [socket, setSocket] = useState(null); // State to hold the socket instance

  useEffect(() => {
    if (pages[currentPageIndex]) {
      setCurrentPageProgrammingLanguage(
        pages[currentPageIndex]?.programming_language
      );
    }
  }, [currentSubtopic, currentPageIndex, pages]);

  useEffect(() => {
    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, [socket]);

  const generateSessionId = (length) => {
    const array = new Uint8Array(length / 2);
    window.crypto.getRandomValues(array);
    return Array.from(array, (dec) => ("0" + dec.toString(16)).slice(-2)).join(
      ""
    );
  };

  const sessionId = generateSessionId(12);

  const handleSubmit = (e) => {
    e.preventDefault();
    setUserCodeOutput("");
    setIsLoading(true);
    setIsError(false);

    // Establish Socket.IO connection
    const socketConnection = io(`wss://tpcg1.tutorialspoint.com`, {
      withCredentials: true,
      transports: ["websocket"],
      upgrade: false,
      query: {
        sessionId: sessionId,
        lang: currentPageProgrammingLanguage,
        EIO: 4,
        transport: "websocket",
      },
    });

    socketConnection.on("connect", () => {
      console.log("Connected to Socket.IO server");

      // Send code to be executed
      socketConnection.emit("code", {
        code: userCode,
        language: currentPageProgrammingLanguage,
        sessionId,
      });
    });

    socketConnection.on("connect_error", (error) => {
      console.error("Connection error:", error);
      setIsError(true);
      setIsLoading(false);
    });

    socketConnection.on("output", (event) => {
      setIsLoading(false);
      setInputNeeded(true);
      setUserCodeOutput((prevOutput) => prevOutput.concat(event));
    });

    socketConnection.on("disconnect", () => {
      console.log("Disconnected from Socket.IO server");
    });

    setSocket(socketConnection); // Set the socket instance in state
  };

  const handleInputSubmit = (e) => {
    e.preventDefault();
    if (input === "clear") {
      setUserCodeOutput("");
      setInput("");
      return;
    }
    if (socket) {
      socket.emit("command", input);
    }
    setInput("");
  };

  const handleResetCode = () => {
    setUserCode(originalCode);
    setUserCodeOutput("");
  };

  return (
    <Box sx={{ my: 2, backgroundColor: "#30322F", borderRadius: 2 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Button
            sx={{
              borderEndStartRadius: 0,
              borderEndEndRadius: 0,
              fontSize: 14,
              bgcolor: "#434842",
              color: "#E3F7BA",
              px: 2,
              cursor: "pointer",
            }}
          >
            {currentFileName}
          </Button>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button onClick={handleResetCode}>
            <Refresh sx={{ color: "#D0FA6F", fontSize: 20 }} />
          </Button>
          {!isLoading ? (
            <Button onClick={handleSubmit}>
              <PlayArrowOutlinedIcon sx={{ color: "#D0FA6F", fontSize: 23 }} />
            </Button>
          ) : (
            <Box sx={{ px: 3 }}>
              <CircularProgress sx={{ color: "#D0FA6F" }} size={15} />
            </Box>
          )}
        </Box>
      </Box>
      <Editor
        height={"30vh"}
        theme="vs-dark"
        defaultLanguage={pages[currentPageIndex]?.programming_language}
        value={userCode}
        path={currentFileName}
        onChange={(value) => {
          setUserCode(value);
        }}
        options={{
          minimap: { enabled: false },
          scrollBeyondLastLine: false,
        }}
      />
      <Box
        sx={{
          bgcolor: "#1F201E",
          borderEndStartRadius: 5,
          borderEndEndRadius: 5,
        }}
      >
        <Typography
          sx={{
            fontSize: 14,
            color: "#434842",
            px: 2,
            py: 0.5,
          }}
        >
          Output:{" "}
        </Typography>
        <Box
          ref={(container) => {
            if (container) {
              container.scrollTop = container.scrollHeight;
            }
          }}
          sx={{
            px: 2,
            py: 0.5,
            overflowY: "auto",
            height: "16vh",
            whiteSpace: "pre-wrap",
          }}
        >
          <Typography
            sx={{ fontSize: 14, color: !isError ? "#E3F7BA" : "#FF6B6B" }}
          >
            {userCodeOutput}
          </Typography>
          {inputNeeded && (
            <Box>
              <form onSubmit={handleInputSubmit}>
                <TextField
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  fullWidth
                  size="small"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    sx: {
                      color: "#E3F7BA",
                      fontSize: 14,
                    },
                    startAdornment: "> ",
                  }}
                  autoFocus
                  spellCheck="false"
                />
              </form>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CodeEditor;
