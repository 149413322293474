import React from "react";
import { Box } from "@mui/material";
import Marquee from "react-fast-marquee";
import SubjectCard from "./subject-card";

const Curriculums = () => {
  return (
    <Box id="Curriculum" sx={{ pt: 10 }}>
      <Marquee autoFill pauseOnHover direction="right" speed={35}>
        <SubjectCard subject={"C (Programming Language)"} />
        <SubjectCard subject={"Advanced Programming"} />
        <SubjectCard subject={"Computer Architecture"} />
        <SubjectCard subject={"Operating Systems"} />
        <SubjectCard subject={"Computer Networking"} />
      </Marquee>
      <Marquee autoFill pauseOnHover direction="left" speed={35}>
        <SubjectCard subject={"Databases"} />
        <SubjectCard subject={"Distributed Systems"} />
        <SubjectCard subject={"Languages and Compilers"} />
        <SubjectCard subject={"Data Structures and Algorithms"} />
        <SubjectCard subject={"Discrete Mathematics"} />
      </Marquee>
    </Box>
  );
};

export default Curriculums;
