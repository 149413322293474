import axios from "axios";
import { store } from "../redux/store";
import { logout, setNewAccessToken } from "../redux/auth/authSlice";

let currentAccessToken = null;

const getLocalAccessToken = () => {
  if (currentAccessToken) {
    return currentAccessToken;
  }
  const currentState = store.getState();
  const { auth } = currentState;
  const { user } = auth;
  const { access } = user;
  currentAccessToken = access;
  return currentAccessToken;
};

const setLocalAccessToken = (token) => {
  currentAccessToken = token;
};

export const axiosAuthInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL,
  // timeout: 10000,
});

// Add a request interceptor
axiosAuthInstance.interceptors.request.use(
  (config) => {
    const accessToken = getLocalAccessToken();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor
axiosAuthInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const currentState = store.getState();
        const { auth } = currentState;
        const { user } = auth;
        const { refresh } = user;
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_API_URL}/auth/refresh/`,
          {
            refresh,
          }
        );
        const { access, refresh: newRefresh } = response.data;

        // Update both Redux store and local variable
        store.dispatch(setNewAccessToken({ access, refresh: newRefresh }));
        setLocalAccessToken(access);

        // Update the original request with the new token
        originalRequest.headers.Authorization = `Bearer ${access}`;

        return axiosAuthInstance(originalRequest);
      } catch (error) {
        store.dispatch(logout());
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

export const axiosNonAuthInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL,
});

// This function can be used to manually update the token if needed
export const setClientToken = (token) => {
  setLocalAccessToken(token);
};
