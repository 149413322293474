import React from "react";
import { Input } from "@mui/material";

const TextInput = ({ id, label, type, placeholder, onChange, value }) => {
  return (
    <Input
      id={id}
      label={label}
      type={type}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      required
      disableUnderline
      sx={{
        bgcolor: "#1F201E",
        width: "35vw",
        p: 1.5,
        px: 3,
        borderRadius: 10,
        my: 0.5,
        color: "#fff",
        "& input": {
          color: "#fff",
          "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px #1F201E inset",
            WebkitTextFillColor: "#fff",
            transition: "background-color 5000s ease-in-out 0s",
          },
          "&:-webkit-autofill:focus, &:-webkit-autofill:hover": {
            WebkitBoxShadow: "0 0 0 1000px #1F201E inset",
            WebkitTextFillColor: "#fff",
          },
          "&:-internal-autofill-selected": {
            backgroundColor: "#1F201E !important",
            WebkitTextFillColor: "#fff",
          },
        },
      }}
    />
  );
};

export default TextInput;
