import React, { useRef, useState } from "react";
import { Box, Typography, IconButton, MenuItem, Menu } from "@mui/material";
import { useNavigate, useLocation } from "react-router";
import { Link as ScrollLink } from "react-scroll";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import CustomButton from "../../common/button";
import EdsgerAILogo from "../../common/logo";

const TitleTypography = ({ value }) => {
  return (
    <Typography
      sx={{
        px: 2,
        color: "#687066",
        cursor: "pointer",
        "&:hover": { color: "primary.main" },
      }}
    >
      {value}
    </Typography>
  );
};

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isMenuOpen, setMenuOpen] = useState(false);
  const menuAnchorRef = useRef();

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const handleLoginButton = () => {
    navigate("/login");
  };

  const handleSignUpButton = () => {
    navigate("/signup");
  };

  const handleLogoClicked = () => {
    navigate("/");
  };

  return (
    <Box
      display="flex"
      alignContent="flex"
      justifyContent="space-between"
      position="sticky"
      top={0}
      zIndex={1000}
      sx={{
        backdropFilter: "blur(50px)",
        backgroundColor: "rgba(31, 32, 30, 0.3)",
        py: location.pathname === "/" ? 1 : 1.2,
        px: { md: 15, sm: 4, xs: 4 },
      }}
    >
      {/* Flex Start */}
      {/* <SynapseLogo onClick={handleLogoClicked} /> */}
      <EdsgerAILogo onClick={handleLogoClicked} />
      {location.pathname !== "/login" && location.pathname !== "/signup" && (
        <>
          {/* Center */}
          <Box
            display={{ xs: "none", md: "flex" }}
            flexDirection="row"
            alignItems="center"
          >
            <ScrollLink to="Curriculum" smooth={true} duration={500}>
              <TitleTypography value={"Curriculum"} />
            </ScrollLink>
            <ScrollLink to="Features" smooth={true} duration={500}>
              <TitleTypography value={"Features"} />
            </ScrollLink>
            <ScrollLink to="Pricing" smooth={true} duration={500}>
              <TitleTypography value={"Pricing"} />
            </ScrollLink>
          </Box>
          {/* Flex End */}
          <Box
            display={{ xs: "none", md: "flex" }}
            flexDirection="row"
            alignItems="center"
          >
            <Box onClick={handleSignUpButton}>
              <TitleTypography value={"Sign up"} />
            </Box>
            <CustomButton
              onClick={handleLoginButton}
              value={"Login"}
              type={"navbar"}
            />
          </Box>
          {/* Responsive Button */}
          <Box display={{ xs: "flex", md: "none" }}>
            <IconButton
              ref={menuAnchorRef}
              edge="start"
              aria-label="menu"
              onClick={handleMenuOpen}
            >
              <MenuRoundedIcon sx={{ color: "#687066" }} />
            </IconButton>
            <Menu
              anchorEl={menuAnchorRef.current}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={isMenuOpen}
              onClose={handleMenuClose}
              sx={{
                "& .MuiPaper-root": {
                  backgroundColor: "#1F201E",
                  borderRadius: 3,
                },
              }}
            >
              <MenuItem onClick={handleMenuClose}>
                <ScrollLink to="Curriculum" smooth={true} duration={500}>
                  <Typography>Curriculum</Typography>
                </ScrollLink>
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <ScrollLink to="Features" smooth={true} duration={500}>
                  <Typography>Features</Typography>
                </ScrollLink>
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <ScrollLink to="Pricing" smooth={true} duration={500}>
                  <Typography>Pricing</Typography>
                </ScrollLink>
              </MenuItem>
              <MenuItem>
                <CustomButton
                  onClick={handleLoginButton}
                  value={"Login"}
                  type={"navbar"}
                />
              </MenuItem>
            </Menu>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Navbar;
