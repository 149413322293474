import { Box } from "@mui/material";
import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import ForceGraph2d from "react-force-graph-2d";
import { useDispatch, useSelector } from "react-redux";
import {
  getHomepage,
  getPagesBySubtopicName,
} from "../../../../redux/tutor/tutorSlice";
import { useNavigate } from "react-router-dom";
import * as d3 from "d3";
import StartLessonModal from "../../../../components/startlesson-modal";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";

import SettingsModal from "../../../../components/setting-modal";
import { axiosAuthInstance } from "../../../../utils/axiosInstance";
import LoadingAnimation from "../../../../components/loading-animation";
import TransitionAlert from "../../../../components/transition-alert";

const SynapseNodes = () => {
  const dispatch = useDispatch();
  const homepageData = useSelector((state) => state.tutor.homepageData);
  const homepageLoading = useSelector((state) => state.tutor.homepageLoading);
  const [openSubtopic, setOpenSubtopic] = useState(false);
  const [modalContentSubtopic, setModalContentSubtopic] = useState("");
  const [openSettings, setOpenSettings] = useState(false);
  const fgRef = useRef();
  const containerRef = useRef();
  const navigate = useNavigate();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [currentZoom, setCurrentZoom] = useState(0.5);

  const [clickedNode, setClickedNode] = useState(null);
  const [subjectClicked, setSubjectClicked] = useState(false);

  const handleOpenSubtopic = useCallback((content) => {
    setModalContentSubtopic(content);
    setOpenSubtopic(true);
  }, []);

  const handleCloseSubtopic = useCallback(() => {
    setOpenSubtopic(false);
    axiosAuthInstance.post("/usertracking/user-press-close-subtopic-modal/");
  }, []);

  const handleStartLesson = useCallback(() => {
    handleCloseSubtopic();
    navigate("/lesson", {
      state: {
        title: modalContentSubtopic.title,
        subtopic: modalContentSubtopic.subtopic,
      },
    });
    axiosAuthInstance.post("/usertracking/user-press-start-lesson/", {
      // subtopic_name: modalContentSubtopic.title.split(" / ")[2],
      subtopic_name: modalContentSubtopic.subtopic,
    });
  }, [
    handleCloseSubtopic,
    navigate,
    modalContentSubtopic.title,
    modalContentSubtopic.subtopic,
  ]);

  const handleOpenSettings = useCallback(() => {
    axiosAuthInstance.post("/usertracking/user-press-settings/");
    setOpenSettings(true);
  }, []);

  const handleCloseSettings = useCallback(() => setOpenSettings(false), []);

  // Loading animation
  useEffect(() => {
    dispatch(getHomepage());
  }, [dispatch]);

  const initialSubjectPositions = useMemo(
    () => ({
      3: { x: 15000, y: 10000 }, // C (Programming Language)
      4: { x: -10000, y: 15000 }, // Advanced Programming
      5: { x: 10000, y: -500 }, // Computer Architecture
      20: { x: -5000, y: -10000 }, // Discrete Mathematics
      8: { x: -200, y: -10000 }, // Operating Systems
      9: { x: 0, y: 10000 }, // Computer Networking
      10: { x: 800, y: 2000 }, // Databases
      12: { x: 10000, y: -500 }, // Distributed Systems
      17: { x: -200, y: 100 }, // Languages and Compilers
      18: { x: -10000, y: -300 }, // Data Structures and Algorithms
    }),
    []
  );

  // Map links and nodes
  const { nodes, links } = useMemo(() => {
    const nodes = [];
    const links = [];

    homepageData.forEach((subject) => {
      nodes.push({
        id: subject.id,
        label: subject.name,
        type: "subject",
        ...initialSubjectPositions[subject.id],
      });

      subject.topics.forEach((topic) => {
        const topicId = `topic-${topic.id}`;
        nodes.push({
          id: topicId,
          label: topic.name,
          type: "topic",
          test: "test",
        });

        links.push({
          source: subject.id,
          target: topicId,
          type: "subjectTopic",
        });

        topic.subtopics.forEach((subtopic) => {
          const subtopicId = `subtopic-${subtopic.id}`;
          nodes.push({
            id: subtopicId,
            label: subtopic.name,
            type: "subtopic",
            subjectName: subject.name,
            topicName: topic.name,
            subtopicStatus: subtopic.status,
          });

          links.push({
            source: topicId,
            target: subtopicId,
            type: "topicConcept",
          });
        });
      });
    });

    return { nodes, links };
  }, [homepageData, initialSubjectPositions]);

  const datas = useMemo(() => ({ nodes, links }), [nodes, links]);

  const highlightNodes = useRef(new Set());
  const highlightLinks = useRef(new Set());
  const clickedNodes = useRef(new Set());
  const clickedLinks = useRef(new Set());

  // To find if nodes are neighbours
  useEffect(() => {
    nodes.forEach((node) => {
      node.neighbors = [];
      node.links = [];
    });

    links.forEach((link) => {
      const sourceNode = nodes.find((node) => node.id === link.source);
      const targetNode = nodes.find((node) => node.id === link.target);
      if (sourceNode && targetNode) {
        sourceNode.neighbors.push(targetNode);
        sourceNode.links.push(link);
        targetNode.neighbors.push(sourceNode);
        targetNode.links.push(link);
      }
    });
  }, [nodes, links]);

  // Nodes distance and charge strength
  useEffect(() => {
    if (fgRef.current) {
      const graph = fgRef.current;
      graph.d3Force("link").distance(() => Math.random() * 100);
      graph.d3Force("charge").strength(-70);
      graph.d3Force("center", d3.forceCenter(0, 0));
      graph.d3Force("x", d3.forceX(10).strength(0.1));
      graph.d3Force("y", d3.forceY(0).strength(0.1));
      graph.d3Force("collide", null);
      graph.d3Force("forceManyBody", null);
    }
  }, [datas]);

  const handleNodeHover = useCallback(
    (node) => {
      highlightNodes.current.clear();
      highlightLinks.current.clear();

      //   if (node) {
      //     highlightNodes.current.add(node);
      //     node.hovered = true; // Set hovered to true when hovered
      //     if (node.neighbors) {
      //       node.neighbors.forEach((neighbor) =>
      //         highlightNodes.current.add(neighbor)
      //       );
      //     }
      //     if (node.links) {
      //       node.links.forEach((link) => highlightLinks.current.add(link));
      //     }
      //   } else {
      //     // Set hovered to false for all nodes when not hovered
      //     nodes.forEach((node) => {
      //       node.hovered = false;
      //     });
      //   }
      // },
      if (node) {
        if (node.type === "subject" || subjectClicked) {
          highlightNodes.current.add(node);
          node.hovered = true; // Set hovered to true when hovered

          if (node.neighbors) {
            node.neighbors.forEach((neighbor) =>
              highlightNodes.current.add(neighbor)
            );
          }
          if (node.links) {
            node.links.forEach((link) => highlightLinks.current.add(link));
          }
        }
      } else {
        // Set hovered to false for all nodes when not hovered
        nodes.forEach((node) => {
          node.hovered = false;
        });
      }
    },

    [highlightLinks, highlightNodes, nodes, subjectClicked]
  );

  const handleNodeClick = useCallback(
    (node) => {
      setClickedNode(node);

      // Track the click event
      const trackClickEvent = async (node) => {
        try {
          let url;
          let body;

          if (node.type === "subject") {
            url = "/usertracking/user-press-subject/";
            body = { subject_id: node.id };
          } else if (node.type === "topic") {
            // console.log(node.test);
            url = "/usertracking/user-press-topic/";
            body = { topic_id: node.id };
          } else if (node.type === "subtopic") {
            url = "/usertracking/user-press-subtopic/";
            body = { subtopic_id: node.id };
          }

          if (url && body) {
            await axiosAuthInstance.post(url, body);
          }
        } catch (error) {
          console.error("Error tracking node click:", error);
        }
      };

      // Call the tracking function
      trackClickEvent(node);

      // When user clicked subtopic dispatch lesson pages
      if (node.type === "subtopic") {
        dispatch(getPagesBySubtopicName(node.label))
          .then(() => {
            const content = {
              title: `${node.subjectName} / ${node.topicName} /`,
              subtopic: `${node.label}`,
              status: node.subtopicStatus,
            };
            handleOpenSubtopic(content);
          })
          .catch((error) => {
            console.error("Error fetching subtopic data:", error);
          });
      }

      // When user click new node, clear previous node clicked
      clickedNodes.current.clear();
      clickedLinks.current.clear();

      // Set node clicked to false initially
      nodes.forEach((node) => {
        node.clicked = false;
      });

      if (node) {
        clickedNodes.current.add(node);
        node.clicked = true; // Set clicked to true when clicked
        const neighbourNodes = [node];

        if (node.type !== "subtopic") {
          if (node.neighbors) {
            node.neighbors.forEach((neighbor) => {
              clickedNodes.current.add(neighbor);
              neighbourNodes.push(neighbor);
            });
          }
          if (node.links) {
            node.links.forEach((link) => clickedLinks.current.add(link));
          }

          const [minX, maxX, minY, maxY] = neighbourNodes.reduce(
            ([minX, maxX, minY, maxY], node) => [
              Math.min(minX, node.x),
              Math.max(maxX, node.x),
              Math.min(minY, node.y),
              Math.max(maxY, node.y),
            ],
            [Infinity, -Infinity, Infinity, -Infinity]
          );

          const centerX = (minX + maxX) / 2;
          const centerY = (minY + maxY) / 2;
          const width = maxX - minX;
          const height = maxY - minY;
          const maxDimension = Math.max(width, height);
          const zoomLevel = Math.min(8, 600 / maxDimension);

          fgRef.current.centerAt(centerX, centerY, 1000);
          fgRef.current.zoom(zoomLevel, 2000);
          setCurrentZoom(zoomLevel);
        }
      }
    },
    [dispatch, handleOpenSubtopic, nodes]
  );

  const handleCanvasClick = useCallback(() => {
    setClickedNode(null);
    // Clear everything from nodes
    clickedNodes.current.clear();
    clickedLinks.current.clear();
    highlightNodes.current.clear();
    highlightLinks.current.clear();
    nodes.forEach((node) => {
      node.clicked = false;
      node.hovered = false;
    });
  }, [nodes]);

  const zoomStep = 1.2;

  useEffect(() => {
    if (fgRef.current) {
      setCurrentZoom(fgRef.current.zoom());
    }
  }, []);

  const zoomIn = useCallback(() => {
    if (fgRef.current) {
      const zoomLevel = currentZoom * zoomStep;
      fgRef.current.zoom(zoomLevel, 400);
      setCurrentZoom(zoomLevel);
    }
  }, [currentZoom]);

  const zoomOut = useCallback(() => {
    if (fgRef.current) {
      const zoomLevel = currentZoom / zoomStep;
      fgRef.current.zoom(zoomLevel, 400);
      setCurrentZoom(zoomLevel);
    }
  }, [currentZoom]);

  const resetZoom = useCallback(() => {
    if (fgRef.current) {
      fgRef.current.zoomToFit(400, 80); // Reset graph to fit within 400ms with 80px padding

      setTimeout(() => {
        if (fgRef.current) {
          setCurrentZoom(fgRef.current.zoom());
        }
      }, 400);
    }
    axiosAuthInstance.post("/usertracking/user-press-zoom-out/");
  }, []);

  // Paint label to canvas
  const nodeCanvasObject = useCallback(
    (node, ctx, globalScale) => {
      const fontSize = 12 / globalScale;
      ctx.font = `${fontSize}px PowerGrotesk-Regular`;
      ctx.fillStyle = "white";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";

      const showLabel = node.type === "subject" || node.clicked;

      // Check if the clicked node is a subject
      const clickedSubject = clickedNode && clickedNode.type === "subject";

      // Case when clickedNode's neighbors are subtopics
      if (
        clickedNode &&
        clickedNode.neighbors.includes(node) &&
        node.type === "subtopic"
      ) {
        ctx.fillText(node.label, node.x, node.y + 10);
      }
      // Case when clickedNode's neighbors are topics (hide topic labels if subject is clicked)
      else if (
        clickedNode &&
        clickedNode.neighbors.includes(node) &&
        node.type === "topic" &&
        !clickedSubject // Hide topic label when subject is clicked
      ) {
        ctx.fillText(node.label, node.x, node.y + 17);
      }
      // Regular label rendering
      else if (showLabel) {
        ctx.fillText(node.label, node.x, node.y + 17);
      }
    },
    [clickedNode]
  );

  const nodeCanvasObjectMode = useCallback(
    (node) => (highlightNodes.current.has(node) ? "before" : "after"),
    []
  );

  // For responsiveness
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries[0]) {
        const { width, height } = entries[0].contentRect;
        setDimensions({ width, height });
      }
    });
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }
    return () => resizeObserver.disconnect();
  }, []);

  return (
    <>
      <Box
        onClick={handleCanvasClick}
        ref={containerRef}
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        <Box display="flex" justifyContent="flex-end" sx={{ paddingRight: 3 }}>
          <Box
            sx={{
              position: "fixed",
              top: 20,
              zIndex: 1000,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                backdropFilter: "blur(10px)",
                backgroundColor: "rgba(31, 32, 30, 0.3)",
                width: 40,
                height: 40,
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 1,
                cursor: "pointer",
              }}
            >
              <SettingsRoundedIcon
                sx={{
                  color: "#687066",
                  fontSize: 20,
                  cursor: "pointer",
                  "&:hover": {
                    color: "#D4FF71",
                  },
                }}
                onClick={handleOpenSettings}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              sx={{
                display: "flex",
                backdropFilter: "blur(10px)",
                backgroundColor: "rgba(31, 32, 30, 0.3)",
                width: 40,
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 1,
                my: 2,
              }}
            >
              <AddRoundedIcon
                sx={{
                  color: "#687066",
                  fontSize: 25,
                  mt: 0.5,
                  mb: 1.5,
                  cursor: "zoom-in",
                  "&:hover": {
                    color: "#D4FF71",
                  },
                }}
                onClick={zoomIn}
              />
              <RefreshRoundedIcon
                sx={{
                  color: "#687066",
                  fontSize: 25,
                  my: 0.5,
                  cursor: "pointer",
                  "&:hover": {
                    color: "#D4FF71",
                  },
                }}
                onClick={resetZoom}
              />
              <RemoveRoundedIcon
                sx={{
                  color: "#687066",
                  fontSize: 25,
                  mb: 0.5,
                  mt: 1.5,
                  cursor: "zoom-out",
                  "&:hover": {
                    color: "#D4FF71",
                  },
                }}
                onClick={zoomOut}
              />
            </Box>
          </Box>
        </Box>
        {homepageLoading ? (
          <LoadingAnimation />
        ) : (
          <Box display="flex" alignItems="center">
            <ForceGraph2d
              ref={fgRef}
              width={dimensions.width}
              height={dimensions.height}
              linkDirectionalParticles={(link) => {
                return link.source.subtopicStatus === "In Progress" ||
                  link.target.subtopicStatus === "In Progress"
                  ? 1
                  : 0;
              }}
              linkDirectionalParticleColor={() => "#BDEE63"}
              nodeColor={(node) => {
                const isActive =
                  highlightNodes.current.size > 0 ||
                  clickedNodes.current.size > 0;
                const isSubject = node.type === "subject";
                const isInProgress =
                  node.subtopicStatus === "In Progress" ||
                  (node.type === "topic" &&
                    Array.isArray(node.neighbors) &&
                    node.neighbors.some(
                      (n) => n.subtopicStatus === "In Progress"
                    ));
                const isCompleted = node.subtopicStatus === "Completed";
                const isHighlighted =
                  highlightNodes.current.has(node) ||
                  clickedNodes.current.has(node);

                if (isSubject || isInProgress) {
                  return "#D4FF71"; // Color for subjects and nodes in progress
                } else if (isCompleted) {
                  return "#449C3D"; // Color for completed nodes
                } else if (isActive) {
                  return isHighlighted ? "#262725" : "rgba(38, 39, 37, 0.3)"; // Color for active nodes
                } else {
                  return "#262725"; // Default color
                }
              }}
              linkColor={(link) => {
                const isActive =
                  highlightLinks.current.size > 0 ||
                  clickedLinks.current.size > 0;
                const isHighlighted =
                  highlightLinks.current.has(link) ||
                  clickedLinks.current.has(link);

                const sourceNode = link.source;
                const targetNode = link.target;

                if (
                  (sourceNode.type === "subject" &&
                    targetNode.type === "topic" &&
                    targetNode.neighbors?.some(
                      (n) => n.subtopicStatus === "In Progress"
                    )) ||
                  (sourceNode.type === "topic" &&
                    targetNode.type === "subtopic" &&
                    targetNode.subtopicStatus === "In Progress")
                ) {
                  return "#D4FF71";
                }
                if (
                  sourceNode.type === "topic" &&
                  targetNode.type === "subtopic" &&
                  targetNode.subtopicStatus === "Completed"
                ) {
                  return "#449C3D";
                }

                if (isActive) {
                  return isHighlighted ? "#262725" : "rgba(38, 39, 37, 0.3)";
                } else {
                  return "#262725";
                }
              }}
              linkWidth={(link) => {
                const isActive =
                  highlightLinks.current.size > 0 ||
                  clickedLinks.current.size > 0;
                const isHighlighted =
                  highlightLinks.current.has(link) ||
                  clickedLinks.current.has(link);
                if (isActive) {
                  return isHighlighted ? 3 : 2;
                } else {
                  return 2;
                }
              }}
              graphData={datas}
              nodeVal={(node) =>
                Math.pow(
                  node.type === "subject"
                    ? 1.5
                    : node.type === "topic"
                    ? 1.3
                    : 1,
                  3
                )
              }
              nodeCanvasObject={nodeCanvasObject}
              nodeCanvasObjectMode={nodeCanvasObjectMode}
              onNodeClick={handleNodeClick}
              onNodeHover={handleNodeHover}
            />
          </Box>
        )}
        {/* <Box display="flex" justifyContent="flex-end" sx={{ paddingRight: 3 }}>
          <Box
            sx={{
              position: "fixed",
              bottom: 20,
              zIndex: 1000,
            }}
          >
            <TransitionAlert />
          </Box>
        </Box> */}
      </Box>
      <StartLessonModal
        openSubtopic={openSubtopic}
        handleCloseSubtopic={handleCloseSubtopic}
        handleStartLesson={handleStartLesson}
        modalContentSubtopic={modalContentSubtopic}
      />
      <SettingsModal
        openSettings={openSettings}
        handleCloseSettings={handleCloseSettings}
      />
    </>
  );
};

export default SynapseNodes;
