import { Box, Typography, Button, Input, TextField } from "@mui/material";
import React, { useState } from "react";
import AnswerExplanation from "../../../../components/answer-explanation";
import AskTutor from "../../../../components/ask-tutor";
import ReactMarkdown from "react-markdown";
import axios from "axios";
import { styled } from "@mui/system";
import MarkdownRenderer from "../../../../components/markdown-renderer";

const ChoiceButton = styled(Button)(({ theme }) => ({
  width: "100%",
  margin: "5px 0",
  textAlign: "left",
  justifyContent: "flex-start",
  padding: "6px 20px",
  borderRadius: 100,
  backgroundColor: "#111210",
  color: "#ECEEEC",
  fontSize: 14,
  "&:hover": {
    backgroundColor: "#434842",
  },
  "&.selected": {
    backgroundColor: "#D4FF71",
    color: "#000",
  },

  "&:focus": {
    backgroundColor: "#D4FF71",
    color: "#000",
  },

  // keep disabled color the same as the normal color
  "&.Mui-disabled": {
    color: "#ECEEEC",
  },

  "&.Mui-disabled.selected": {
    backgroundColor: "#D4FF71",
    color: "#000",
  },
}));

const LessonTypeThree = ({
  question,
  showAnswer,
  currentPage,
  setShowAnswer,
  answerChoices,
  isMultipleChoice,
  userAnswer,
  setUserAnswer,
  setResponse,
  response,
  messages,
  setMessages,
  messagesCount,
  setMessagesCount,
  pageHistory,
  setPageHistory,
  pageId,
  suggestion
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSendMessage = () => {
    if (!userAnswer) return;
    setIsLoading(true);
    axios
      .post(
        process.env.REACT_APP_BACKEND_API_URL +
          (isMultipleChoice
            ? `/learncs/answer-page-multiple-choice-question/${currentPage.id}/`
            : `/learncs/answer-page-question/${currentPage.id}/`),
        {
          answer: userAnswer,
        }
      )
      .then((response) => {
        setResponse(response.data);
        setShowAnswer(true);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  return (
    <Box sx={{ my: 10 }}>
      <Box
        sx={{
          backgroundColor: "#1F201E",
          borderRadius: 2,
          p: 3,
        }}
      >
        <Box>
          <Typography>
            <MarkdownRenderer content={question} />
          </Typography>
        </Box>

        {isMultipleChoice ? (
          <Box sx={{ mt: 2 }}>
            {answerChoices.map((choice) => (
              <ChoiceButton
                key={choice.id}
                onClick={() => setUserAnswer(choice.text)}
                className={userAnswer === choice.text ? "selected" : ""}
                disabled={showAnswer || isLoading}
                sx={{
                  backgroundColor:
                    userAnswer === choice.text && showAnswer
                      ? "#E3F7BA !important"
                      : "",
                  color: userAnswer === choice.text ? "#000" : "",
                }}
              >
                {choice.text}
              </ChoiceButton>
            ))}
          </Box>
        ) : (
          <Box>
            <Input
              autoComplete="off"
              placeholder="Type your answer"
              value={userAnswer}
              onChange={(e) => setUserAnswer(e.target.value)}
              fullWidth
              required
              disableUnderline={true}
              multiline
              sx={{
                mt: 2,
                py: 1,
                px: 3,
                borderRadius: 2,
                color: "#fff",
                fontSize: 16,
              }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  handleSendMessage();
                }
              }}
              spellCheck={false}
            />
          </Box>
        )}

        {!showAnswer && (
          <Box display="flex" justifyContent="center" sx={{ mt: 2.5 }}>
            <Button
              variant="contained"
              sx={{
                fontSize: 14,
                backgroundColor: userAnswer ? "#D4FF71" : "#30322F",
                "&:hover": {
                  backgroundColor: userAnswer ? "#D4FF71" : "#30322F",
                },
                borderRadius: 100,
              }}
              onClick={handleSendMessage}
              disableElevation={true}
            >
              Check Answer
            </Button>
          </Box>
        )}
        {showAnswer && <AnswerExplanation answer={response} />}
      </Box>
      <AskTutor
        messages={messages}
        setMessages={setMessages}
        messagesCount={messagesCount}
        setMessagesCount={setMessagesCount}
        pageHistory={pageHistory}
        setPageHistory={setPageHistory}
        pageId={pageId}
        suggestion={suggestion}
      />
    </Box>
  );
};

export default LessonTypeThree;
