import { Typography, Box } from "@mui/material";
import React from "react";
import FeatureBox from "./feature-box";
import GetLowVideo from "../../../assets/videos/1.mp4";
import ActiveLearningVideo from "../../../assets/videos/2.mp4";
import PersonalisedVideo from "../../../assets/videos/3.mp4";

const Features = () => {
  return (
    <Box
      id="Features"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      sx={{ px: { md: 20, sm: 5, xs: 2 }, pt: 20 }}
    >
      <Typography
        sx={{
          fontSize: { md: 40, sm: 35, xs: 30 },
          textAlign: "center",
          mb: 10,
        }}
      >
        Features
      </Typography>
      <Box>
        <FeatureBox
          title={"Beyond Syntax and Frameworks"}
          description={
            <>
              <Typography
                component="span"
                sx={{ fontSize: { md: "inherit", sm: "inherit", xs: 16 } }}
              >
                Build the deep CS knowledge that makes learning new languages
                fast and effortless. <br />
                <br />
                With solid CS foundations, you'll pick up any language in just a
                weekend, leaving the libraries, tooling, and ecosystems to be
                mastered on the job.
              </Typography>
            </>
          }
          videoSource={GetLowVideo}
        />
        <FeatureBox
          title={"Boring Videos, No More"}
          description={
            <>
              <Typography
                component="span"
                sx={{ fontSize: { md: "inherit", sm: "inherit", xs: 16 } }}
              >
                Traditional videos are passive, leaving gaps in your knowledge
                without constant interaction. <br />
                <br />
                Our AI tutor aims to address your knowledge gaps constantly
                throughout the lesson, boosting your motivation to learn CS
                effectively every day.
              </Typography>
            </>
          }
          videoSource={ActiveLearningVideo}
        />
        <FeatureBox
          title={"1-to-1 Guidance"}
          description={
            <>
              <Typography
                component="span"
                sx={{ fontSize: { md: "inherit", sm: "inherit", xs: 16 } }}
              >
                Enjoy real-time guidance with a tutor who answers your questions
                immediately and never gets tired of your curiosity. <br />
                <br />
                No question is too basic, learn at your own pace with endless
                support.
              </Typography>
            </>
          }
          videoSource={PersonalisedVideo}
        />
      </Box>
    </Box>
  );
};

export default Features;
