import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const login = createAsyncThunk("auth/login", async (data) => {
  const response = await axios.post(
    process.env.REACT_APP_BACKEND_API_URL + "/auth/login/",
    data
  );
  return response.data;
});

export const register = createAsyncThunk("auth/register", async (data) => {
  const response = await axios.post(
    process.env.REACT_APP_BACKEND_API_URL + "/auth/register/",
    data
  );
  return response.data;
});

export const logout = createAsyncThunk("auth/logout", async () => {});

export const setNewAccessToken = createAsyncThunk(
  "auth/setNewAccessToken",
  async (data) => {
    return data;
  }
);

export const setNewAccessRefresh = createAsyncThunk(
  "auth/setNewAccessRefresh",
  async (data) => {
    return data;
  }
);

const initialState = {
  isLoading: false,
  isLoggedIn: false,
  user: {},
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
        state.isLoggedIn = true;
      })
      .addCase(login.rejected, (state) => {
        state.isError = true;
      })
      .addCase(setNewAccessToken.fulfilled, (state, action) => {
        state.user = {
          ...state.user,
          access: action.payload.access,
          refresh: action.payload.refresh,
        };
        state.isLoggedIn = true;
      })
      .addCase(logout.fulfilled, (state) => {
        state.isLoggedIn = false;
        state.user = {};
      })
      .addCase(setNewAccessRefresh.fulfilled, (state, action) => {
        state.user = action.payload;
        state.isLoggedIn = true;
      })
      .addCase(register.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
        state.isLoggedIn = true;
      });
  },
});

export default authSlice.reducer;
