import React from "react";
import ReactMarkdown from "react-markdown";
import CodeSnippet from "../code-snippet";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import "katex/dist/katex.min.css";

const MarkdownRenderer = ({ content }) => {
  return (
    <ReactMarkdown
      remarkPlugins={[remarkMath]}
      rehypePlugins={[rehypeKatex]}
      components={{
        code: ({ node, inline, className, children, ...props }) => {
          const match = /language-(\w+)/.exec(className || "");
          return !inline && match ? (
            <CodeSnippet
              language={match[1]}
              code={String(children).replace(/\n$/, "")}
              {...props}
            />
          ) : (
            <code
              className={className}
              {...props}
              style={{
                color: "#e3f7ba",
                backgroundColor: "#223013",
                padding: "0.1em 0.3em",
                borderRadius: "0.2em",
                fontSize: "14px",
              }}
            >
              {children}
            </code>
          );
        },
      }}
    >
      {content}
    </ReactMarkdown>
  );
};

export default MarkdownRenderer;
