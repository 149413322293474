import {
  Box,
  Typography,
  Input,
  InputAdornment,
  Button,
  IconButton,
} from "@mui/material";
import React, { useState, useRef } from "react";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import { axiosAuthInstance } from "../../../../utils/axiosInstance";
import MarkdownRenderer from "../../../../components/markdown-renderer";
import { useSelector } from "react-redux";
import EdsgerAILogo from "../../../../common/logo";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

const TutorChat = () => {
  const auth = useSelector((state) => state.auth);
  const { user } = auth;
  const { user: userInfo } = user;
  const { first_name } = userInfo;
  const [messages, setMessages] = useState([
    {
      role: "assistant",
      content: `Hi ${first_name}, what do you want to learn today?`,
    },
  ]);
  const [prompt, setPrompt] = useState("");
  const [suggestions, setSuggestions] = useState([
    "How Edsger AI works?",
    "Give me suggestions.",
  ]);

  const suggestionsRef = useRef(null); // Create a ref for the suggestions container

  const scrollSuggestions = (direction) => {
    if (suggestionsRef.current) {
      const scrollAmount = 100; // Amount to scroll per click
      suggestionsRef.current.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const handleSendMessage = () => {
    if (prompt) {
      try {
        const userMessage = { content: prompt, role: "user" };
        const updatedMessages = [...messages, userMessage];
        setMessages(updatedMessages);
        setPrompt("");
        axiosAuthInstance
          .post("/learncs/ai-chatbot/", {
            prompt,
            messages,
          })
          .then((response) => {
            const responseMessage = {
              content: response.data,
              role: "assistant",
            };
            setMessages((prevMessages) => [...prevMessages, responseMessage]);
          });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setPrompt(suggestion);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        my: 3,
        minWidth: 450,
        maxWidth: 450,
        borderRight: "solid 2px #1F201E",
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          px: 5,
          py: 1,
          pb: 2,
          top: 0,
          zIndex: 1000, // You can adjust the z-index as needed
        }}
      >
        <EdsgerAILogo />
      </Box>
      <Box
        sx={{
          px: 3,
          overflowY: "auto",
          borderRadius: 3,
          height: "81%",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          msOverflowStyle: "none",
          scrollbarWidth: "none",
        }}
        display="flex"
        flexDirection="column"
        ref={(container) => {
          if (container) {
            container.scrollTop = container.scrollHeight;
            container.style.scrollBehavior = "smooth";
          }
        }}
      >
        <Box
          position="sticky"
          top={0}
          zIndex={1000}
          sx={{
            background:
              "linear-gradient(to bottom, rgba(17, 18, 16, 1), rgba(17, 18, 16, 0))",
            pb: 5,
          }}
        ></Box>
        <Box sx={{ px: { sm: 2, xs: 3 } }}>
          {messages.map((message, index) => (
            <Box
              display="flex"
              key={index}
              sx={{
                flexDirection: "column",
                justifyContent:
                  message.role === "user" ? "flex-end" : "flex-start",
                textAlign: message.role === "user" ? "right" : "left",
                bgcolor: message.role === "user" ? "#1F201E" : "#111210",
                borderRadius: 3,
                // my: 2,
                py: message.role === "user" ? 1 : 0,
                px: message.role === "user" ? 2 : 0,
                marginLeft: message.role === "user" ? "auto" : 0, // Auto margin for user, 0 margin for Synapse
                marginRight: message.role === "user" ? 0 : "auto",
                color: "#fff",
                wordWrap: "break-word",
                width: "fit-content",
                maxWidth: "80%",
                lineHeight: 1.5,
              }}
            >
              {message.role === "user" ? (
                <Typography sx={{ fontSize: 14 }}>{message.content}</Typography>
              ) : (
                <Typography sx={{ fontSize: 14 }}>
                  <MarkdownRenderer content={message.content} />
                </Typography>
              )}
            </Box>
          ))}
        </Box>
        <Box
          position="sticky"
          bottom={0}
          zIndex={1000}
          sx={{
            background:
              "linear-gradient(to top, rgba(17, 18, 16, 1), rgba(17, 18, 16, 0))",
            pt: 4,
          }}
        ></Box>
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            width: "inherit",
            zIndex: 1001,
            pb: 1,
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ width: "400px" }}
          >
            <IconButton onClick={() => scrollSuggestions("left")}>
              <ChevronLeft
                sx={{
                  color: "#223013",
                  width: 15,
                  ":hover": {
                    color: "primary.main",
                  },
                }}
              />
            </IconButton>
            <Box
              ref={suggestionsRef}
              sx={{
                overflowX: "auto", // Enable horizontal scrolling
                whiteSpace: "nowrap", // Prevent wrapping of buttons
                width: "390px", // Fixed width for the scrolling area
                borderRadius: 30,
                scrollbarWidth: "0px", // For Firefox
                "&::-webkit-scrollbar": {
                  height: "0px", // Set height for the scrollbar
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#D4FF71", // Customize scrollbar color
                  borderRadius: "0px", // Rounded scrollbar
                },
              }}
            >
              {suggestions.map((suggestion, index) => (
                <Button
                  key={index}
                  sx={{
                    backgroundColor: "rgba(212, 255, 113, 0.1)",
                    borderRadius: 10,
                    px: 2,
                    color: "#D4FF71",
                    fontSize: 12,
                    mx: 0.2,
                    minWidth: "auto",
                    // Hover color
                    "&:hover": {
                      backgroundColor: "#D4FF71",
                      color: "#111210",
                    },
                  }}
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  {suggestion}
                </Button>
              ))}
            </Box>
            <IconButton onClick={() => scrollSuggestions("right")}>
              <ChevronRight
                sx={{
                  color: "#223013",
                  width: 15,
                  ":hover": {
                    color: "primary.main",
                  },
                }}
              />
            </IconButton>
          </Box>
          <Input
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            fullWidth
            placeholder="Type your question"
            required
            disableUnderline={true}
            sx={{
              backgroundColor: "#1F201E",
              my: 2,
              py: 0.7,
              px: 3,
              borderRadius: 10,
              color: "#ECEEEC",
              fontSize: 14,
              width: "400px",
            }}
            endAdornment={
              <InputAdornment position="end">
                <Box
                  onClick={handleSendMessage}
                  sx={{
                    borderRadius: "50%",
                    backgroundColor: prompt ? "#D4FF71" : "#111210",
                    width: 30,
                    height: 30,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    "&:hover": {
                      bgcolor: "rgba(212, 255, 113, 0.8)",
                    },
                  }}
                >
                  <ArrowUpwardOutlinedIcon
                    sx={{ color: "#1F201E", fontSize: 18 }}
                  />
                </Box>
              </InputAdornment>
            }
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                handleSendMessage();
              }
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default TutorChat;
