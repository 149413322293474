import React from "react";
import { Box, Typography, Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router";
import ExitIcon from "../../assets/icons/exit-icon.svg";
import { axiosAuthInstance } from "../../utils/axiosInstance";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#121311",
  p: 3,
  borderRadius: 3,
  width: 280,
  outline: "none",
};

const LeaveLessonModal = ({ openLeaveLesson, handleCloseLeaveLesson }) => {
  const navigate = useNavigate();
  return (
    <Modal
      open={openLeaveLesson}
      onClose={handleCloseLeaveLesson}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ backdropFilter: "blur(2px)" }}
    >
      <Box sx={style}>
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
          sx={{ height: 420 }}
        >
          <Box display="flex" alignItems="center" flexDirection="column">
            <img
              src={ExitIcon}
              alt="ProfileIcon"
              style={{ width: "25%", paddingBottom: 20, paddingTop: 10 }}
            />
            <Typography sx={{ textAlign: "center", fontSize: 15, py: 2 }}>
              You haven’t finished the lesson yet.
            </Typography>
            <Typography sx={{ textAlign: "center", fontSize: 15 }}>
              If you leave the lesson now then all of your progress will be
              lost.
            </Typography>
          </Box>
          <Box>
            <Button
              sx={{
                color: "#D4FF71",
                border: "solid 1px #D4FF71",
                width: "100%",
                "&:hover": {
                  bgcolor: "rgba(212, 255, 113, 0.1)",
                },
                borderRadius: 100,
              }}
              onClick={() => {
                navigate("/home");
                axiosAuthInstance.post(
                  "usertracking/user-press-confirm-leave-lesson/"
                );
              }}
            >
              Leave
            </Button>
            <Button
              sx={{
                backgroundColor: "#D4FF71",
                color: "#111210",
                mt: 1,
                width: "100%",
                "&:hover": {
                  bgcolor: "rgba(212, 255, 113, 0.8)",
                },
                borderRadius: 100,
              }}
              onClick={handleCloseLeaveLesson}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default LeaveLessonModal;
