import React, { useState, useRef } from "react";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import {
  Box,
  Typography,
  Input,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { axiosAuthInstance } from "../../utils/axiosInstance";
import { useSelector } from "react-redux";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import MarkdownRenderer from "../markdown-renderer";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

const AskTutor = ({
  messages,
  setMessages,
  messagesCount,
  setMessagesCount,
  pageId,
  setPageHistory,
  pageHistory,
  suggestion,
}) => {
  const [showTutor, setShowTutor] = useState(false);
  const tutorState = useSelector((state) => state.tutor);
  const { pages, currentPageIndex } = tutorState;
  const [prompt, setPrompt] = useState("");
  const [responseGenerated, setResponseGenerated] = useState(true);
  const [explain, setExplain] = useState("I don't understand anything.");

  const suggestionsRef = useRef(null);

  const scrollSuggestions = (direction) => {
    if (suggestionsRef.current) {
      const scrollAmount = 100; // Amount to scroll per click
      suggestionsRef.current.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const handleClick = () => {
    setShowTutor(!showTutor);
  };

  const handleSendMessage = async () => {
    // Prevent empty prompt
    if (!responseGenerated || !prompt.trim()) {
      return;
    }
    setResponseGenerated(false);

    const userMessage = { content: prompt, role: "user" };
    const updatedMessages = [...messages, userMessage];
    setMessages(updatedMessages);
    setPrompt("");
    setMessagesCount(messagesCount + 1);

    try {
      const response = await axiosAuthInstance.post(
        `/learncs/ask-followup-page-with-history/${pages[currentPageIndex]?.id}/`,
        { prompt, messages: updatedMessages }
      );
      const responseMessage = { content: response.data, role: "assistant" };
      setMessages((prevMessages) => [...prevMessages, responseMessage]);
    } catch (error) {
      console.log(error);
    }
    setResponseGenerated(true);
  };

  const handleSendMessageWithPageId = async () => {
    // Prevent empty prompt
    if (!responseGenerated || !prompt.trim()) {
      return;
    }
    setResponseGenerated(false);

    const userMessage = { content: prompt, role: "user" };
    const updatedMessages = [...messages, userMessage];
    pageHistory.map((page) => {
      if (page.id === pageId) {
        page.messages = updatedMessages;
        page.messagesCount += 1;
      }
    });
    setPrompt("");

    try {
      const response = await axiosAuthInstance.post(
        `/learncs/ask-followup-page-with-history/${pageId}/`,
        { prompt, messages: updatedMessages }
      );
      const responseMessage = { content: response.data, role: "assistant" };
      const updatedPageHistory = pageHistory.map((page) => {
        if (page.id === pageId) {
          page.messages.push(responseMessage);
        }
        return page;
      });
      setPageHistory(updatedPageHistory);
    } catch (error) {
      console.log(error);
    }
    setResponseGenerated(true);
  };

  const handleClickSuggestion = (suggestion) => {
    const userMessage = { content: suggestion, role: "user" };
    const updatedMessages = [...messages, userMessage];
    setMessages(updatedMessages);
    setMessagesCount(messagesCount + 1);

    try {
      axiosAuthInstance
        .post(
          `/learncs/ask-followup-page-with-history/${pages[currentPageIndex]?.id}/`,
          { prompt: suggestion, messages: updatedMessages }
        )
        .then((response) => {
          const responseMessage = { content: response.data, role: "assistant" };
          setMessages((prevMessages) => [...prevMessages, responseMessage]);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickSuggestionWithPageId = (suggestion) => {
    const userMessage = { content: suggestion, role: "user" };
    const updatedMessages = [...messages, userMessage];
    pageHistory.map((page) => {
      if (page.id === pageId) {
        page.messages = updatedMessages;
        page.messagesCount += 1;
      }
    });

    try {
      axiosAuthInstance
        .post(`/learncs/ask-followup-page-with-history/${pageId}/`, {
          prompt: suggestion,
          messages: updatedMessages,
        })
        .then((response) => {
          const responseMessage = { content: response.data, role: "assistant" };
          const updatedPageHistory = pageHistory.map((page) => {
            if (page.id === pageId) {
              page.messages.push(responseMessage);
            }
            return page;
          });
          setPageHistory(updatedPageHistory);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const truncateText = (text) => {
    if (!text) return ""; // Handle cases where text might be undefined or null

    const words = text.split(" ");
    if (words.length > 7) {
      return words.slice(0, 7).join(" ") + "...";
    }
    return text;
  };

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        onClick={handleClick}
        sx={{
          my: 3,
          justifyContent: showTutor ? "flex-end" : "space-between",
        }}
      >
        {!showTutor && (
          <Box display="flex" flexDirection="row">
            <Button
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                border: pageHistory ? "solid 1px #434842" : "solid 1px #223013",
                px: 2,
                py: 0.5,
                borderRadius: 10,
                fontSize: 12,
                mr: 1,
                color: pageHistory ? "#434842" : "primary.main",
                ":hover": {
                  bgcolor: "primary.main",
                  color: "#111120",
                  "& .hover-box": {
                    backgroundColor: "#111210", // Change Box background color when button is hovered
                  },
                },
              }}
            >
              <Box
                className="hover-box"
                sx={{
                  width: "10px",
                  height: "10px",
                  borderRadius: 100,
                  backgroundColor: pageHistory ? "#434842" : "primary.main",
                  mr: 1,
                }}
              ></Box>
              Explain
            </Button>
            <Button
              sx={{
                bgcolor: pageHistory ? "transparent" : "#223013",
                px: 2,
                py: 0.5,
                borderRadius: 10,
                fontSize: 12,
                border: pageHistory ? "solid 1px #434842" : "none",
                color: pageHistory ? "#434842" : "primary.main",
                ":hover": {
                  bgcolor: "primary.main",
                  color: "#111120",
                },
              }}
            >
              {truncateText(suggestion)}
              {/* {truncateText(pages[currentPageIndex]?.followup_suggestion)} */}
            </Button>
          </Box>
        )}
        <Button
          sx={{
            borderRadius: 100,
            "&:hover": {
              color: "#D4FF71",
              background: "#111210",
              "& > *": {
                color: "#D4FF71",
              },
            },
          }}
        >
          <Typography
            sx={{ color: pageHistory ? "#434842" : "#D4FF71", fontSize: 14 }}
          >
            {messagesCount}
          </Typography>
          {showTutor ? (
            <ExpandLessOutlinedIcon
              sx={{
                color: pageHistory ? "#434842" : "#D4FF71",
                fontSize: 18,
                ml: 0.5,
              }}
            />
          ) : (
            <ExpandMoreOutlinedIcon
              sx={{
                color: pageHistory ? "#434842" : "#D4FF71",
                fontSize: 18,
                ml: 0.5,
              }}
            />
          )}
        </Button>
      </Box>
      {showTutor && (
        <Box>
          <Box
            sx={{
              borderLeft: "solid 2px #1F201E",
              borderRight: "solid 2px #1F201E",
              height: 300,
              overflowY: "auto",
              scrollbarWidth: "none",
            }}
            ref={(container) => {
              if (container) {
                container.scrollTop = container.scrollHeight;
                container.style.scrollBehavior = "smooth";
              }
            }}
          >
            <Box
              position="sticky"
              top={0}
              zIndex={1000}
              sx={{
                background:
                  "linear-gradient(to bottom, rgba(17, 18, 16, 1), rgba(17, 18, 16, 0))",
                pb: 5,
              }}
            ></Box>
            <Box sx={{ px: { sm: 2, xs: 3 } }}>
              {messages.map((message, index) => (
                <Box
                  display="flex"
                  key={index}
                  sx={{
                    flexDirection: "column",
                    justifyContent:
                      message.role === "user" ? "flex-end" : "flex-start",
                    textAlign: message.role === "user" ? "right" : "left",
                    bgcolor: message.role === "user" ? "#1F201E" : "#111210",
                    borderRadius: 3,
                    my: 2,
                    py: message.role === "user" ? 1 : 0,
                    px: message.role === "user" ? 2 : 0,
                    marginLeft: message.role === "user" ? "auto" : 0, // Auto margin for user, 0 margin for Synapse
                    marginRight: message.role === "user" ? 0 : "auto",
                    color: "#fff",
                    wordWrap: "break-word",
                    width: "fit-content",
                    maxWidth: "80%",
                    lineHeight: 1.5,
                  }}
                >
                  {message.role === "user" ? (
                    <Typography sx={{ fontSize: 13 }}>
                      {message.content}
                    </Typography>
                  ) : (
                    <Typography sx={{ fontSize: 13 }}>
                      <MarkdownRenderer content={message.content} />
                    </Typography>
                  )}
                </Box>
              ))}
            </Box>
            <Box
              position="sticky"
              bottom={0}
              zIndex={1000}
              sx={{
                background:
                  "linear-gradient(to top, rgba(17, 18, 16, 1), rgba(17, 18, 16, 0))",
                pt: 5,
              }}
            ></Box>
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            sx={{
              overflowX: "auto", // Enable horizontal scrolling
              whiteSpace: "nowrap", // Prevent wrapping of buttons
              borderRadius: 30,
              mx: 2,
              scrollbarWidth: "0px", // For Firefox
              "&::-webkit-scrollbar": {
                height: "0px", // Set height for the scrollbar
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#D4FF71", // Customize scrollbar color
                borderRadius: "0px", // Rounded scrollbar
              },
            }}
          >
            <Button
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                border: "solid 1px #223013",
                px: 2,
                py: 0.5,
                borderRadius: 10,
                fontSize: 12,
                mx: 0.2,
                color: "primary.main",
                ":hover": {
                  bgcolor: "primary.main",
                  color: "#111120",
                  "& .hover-box": {
                    backgroundColor: "#111210", // Change Box background color when button is hovered
                  },
                },
              }}
              onClick={() =>
                pageId
                  ? handleClickSuggestionWithPageId(explain)
                  : handleClickSuggestion(explain)
              }
            >
              <Box
                className="hover-box"
                sx={{
                  width: "10px",
                  height: "10px",
                  borderRadius: 100,
                  backgroundColor: "primary.main",
                  mr: 1,
                }}
              ></Box>
              Explain
            </Button>
            {/* {pages[currentPageIndex]?.followup_suggestion && ( */}
            {suggestion && (
              <Button
                sx={{
                  backgroundColor: "rgba(212, 255, 113, 0.1)",
                  borderRadius: 10,
                  px: 2,
                  py: 0.5,
                  color: "#D4FF71",
                  fontSize: 12,
                  mx: 0.2,
                  // Hover color
                  "&:hover": {
                    backgroundColor: "#D4FF71",
                    color: "#111210",
                  },
                }}
                onClick={() =>
                  pageId
                    ? handleClickSuggestionWithPageId(
                        // pages[currentPageIndex]?.followup_suggestion
                        suggestion
                      )
                    : handleClickSuggestion(
                        // pages[currentPageIndex]?.followup_suggestion
                        suggestion
                      )
                }
              >
                {suggestion}
                {/* {pages[currentPageIndex]?.followup_suggestion} */}
              </Button>
            )}
          </Box>
          <Input
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            fullWidth
            placeholder="Type your question"
            required
            disableUnderline={true}
            disabled={!responseGenerated}
            sx={{
              backgroundColor: "#1F201E",
              my: 2,
              py: 0.7,
              px: 3,
              borderRadius: 10,
              color: "#ECEEEC",
              fontSize: 14,
            }}
            endAdornment={
              <InputAdornment position="end">
                <Box
                  onClick={
                    responseGenerated
                      ? pageId
                        ? handleSendMessageWithPageId
                        : handleSendMessage
                      : null
                  }
                  sx={{
                    borderRadius: "50%",
                    backgroundColor: prompt ? "#D4FF71" : "#111210",
                    width: 30,
                    height: 30,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <ArrowUpwardOutlinedIcon
                    sx={{ color: "#1F201E", fontSize: 18 }}
                  />
                </Box>
              </InputAdornment>
            }
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                pageId ? handleSendMessageWithPageId() : handleSendMessage();
              }
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default AskTutor;
