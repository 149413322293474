import { Box, Typography } from "@mui/material";
import React from "react";
import CustomButton from "../../../../common/button";

const PricingBox = ({
  title,
  description,
  button,
  price,
  onClick,
  button_type,
  txtcolor,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        backgroundColor: "#1F201E",
        borderRadius: 3,
        py: 5,
        px: 5,
        width: 250,
        m: 1,
      }}
    >
      <Typography sx={{ fontSize: 20 }}>{title}</Typography>
      <Box sx={{ pt: 4, height: "220px" }}>{description}</Box>
      <Typography sx={{ fontSize: 20, textAlign: "center" }}>
        {" "}
        ${price} / month
      </Typography>

      <Box display="flex" justifyContent="center" sx={{ pt: 5 }}>
        <CustomButton
          value={button}
          type={button_type}
          onClick={onClick}
          txtcolor={txtcolor}
        />
      </Box>
    </Box>
  );
};

export default PricingBox;
