import { Typography, Box } from "@mui/material";
import React from "react";

const SubjectCard = ({ subject }) => {
  return (
    <Box
      sx={{
        backgroundColor: "#1F201E",
        px: 3,
        py: 1.5,
        borderRadius: 1.5,
        mx: 1.5,
        my: 1.5,
      }}
    >
      <Typography>{subject}</Typography>
    </Box>
  );
};

export default SubjectCard;
