import React from "react";
import { Box, Typography, Button } from "@mui/material";
import BackgroundNodes from "./background-nodes";
import { useNavigate } from "react-router";
import CustomButton from "../../../common/button";

const StartLearning = () => {
  const navigate = useNavigate();
  const handleTryForFreeButton = () => {
    navigate("/signup");
  };
  return (
    <Box
      sx={{
        height: "90vh",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 0,
        }}
      >
        <BackgroundNodes />
      </Box>
      <Box
        sx={{
          position: "relative",
          zIndex: 1,
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          sx={{ flexGrow: 1, py: 20 }}
        >
          <Typography
            sx={{
              fontSize: { md: 30, sm: 23, xs: 20 },
              textAlign: "center",
              color: "#FFFFFF",
            }}
          >
            Grow Beyond Syntax, <br />
            High-Level Frameworks <br />
            and Interview-Prep
          </Typography>
          <Box sx={{ marginTop: 5 }}>
            <CustomButton
              type={"secondary"}
              value={"Get Started"}
              onClick={handleTryForFreeButton}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default StartLearning;
