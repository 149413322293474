import React from "react";
import { Skeleton, Box } from "@mui/material";

const LoadingSkeletons = () => {
  return (
    <Box display="flex" flexDirection="column" sx={{ my: 5 }}>
      <Skeleton sx={{ width: 160, bgcolor: "#1F201E", borderRadius: 2 }} />
      <Skeleton
        sx={{ width: 400, bgcolor: "#1F201E", borderRadius: 2, my: 0.5 }}
      />
      <Skeleton sx={{ width: "100%", bgcolor: "#1F201E", borderRadius: 2 }} />
      <Skeleton sx={{ width: "100%", bgcolor: "#1F201E", borderRadius: 2 }} />
      <Skeleton sx={{ width: "100%", bgcolor: "#1F201E", borderRadius: 2 }} />
      <Skeleton sx={{ width: "100%", bgcolor: "#1F201E", borderRadius: 2 }} />

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        sx={{ my: 2 }}
      >
        <Box display="flex" flexDirection="row">
          <Skeleton
            sx={{ bgcolor: "#1F201E", borderRadius: 2 }}
            variant="rectangular"
            width={100}
            height={18}
          />
          <Skeleton
            sx={{ bgcolor: "#1F201E", mx: 1, borderRadius: 2 }}
            variant="rectangular"
            width={280}
            height={18}
          />
        </Box>
        <Box>
          <Skeleton
            sx={{ bgcolor: "#1F201E", borderRadius: 2 }}
            variant="rectangular"
            width={100}
            height={18}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default LoadingSkeletons;
