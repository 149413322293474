import { Box, Typography, Button, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import LessonTypeOne from "./type-1";
import LessonTypeTwo from "./type-2";
import LessonTypeThree from "./type-3";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import { useDispatch, useSelector } from "react-redux";
import {
  getPagesBySubtopicName,
  incrementPageIndex,
} from "../../../redux/tutor/tutorSlice";
import { useNavigate, useLocation } from "react-router";
import ProgressBar from "../../../components/progress-bar";
import { axiosAuthInstance } from "../../../utils/axiosInstance";
import LeaveLessonModal from "../../../components/leave-lesson";
import LoadingSkeletons from "../../../components/loading-skeleton";

const Lesson = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { title, subtopic } = location.state || {};
  const tutorState = useSelector((state) => state.tutor);
  const { pages, currentPageIndex, currentSubtopic } = tutorState;
  const dispatch = useDispatch();
  const [userAnswer, setUserAnswer] = useState("");
  const [showAnswer, setShowAnswer] = useState(false);
  const [response, setResponse] = useState("");
  const [userCodeOutput, setUserCodeOutput] = useState("");
  const [currentPage, setCurrentPage] = useState(pages[currentPageIndex]);
  const [userCode, setUserCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [pageHistory, setPageHistory] = useState([]);
  const [openLeaveLesson, setOpenLeaveLesson] = useState(false);
  const [messages, setMessages] = useState([]);
  const [messagesCount, setMessagesCount] = useState(0);

  const handleOpenLeaveLesson = () => {
    setOpenLeaveLesson(true);
    axiosAuthInstance.post("usertracking/user-press-leave-lesson-icon/");
  };

  const handleCloseLeaveLesson = () => {
    setOpenLeaveLesson(false);
    axiosAuthInstance.post("usertracking/user-press-cancel-leave-lesson/");
  };

  // useEffect(() => {
  //   setLoading(true);
  //   if (pages.length === 0) {
  //     dispatch(getPagesBySubtopicName(currentSubtopic));
  //   }
  //   setLoading(false);
  // }, [currentSubtopic, dispatch, pages.length]);

  // Fake loading animation
  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1100);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // console.log("Page History:", pageHistory);
    // console.log("Current Code Editor Content:", pages[currentPageIndex]?.code);
    setCurrentPage(pages[currentPageIndex]);
    setUserCode(pages[currentPageIndex]?.code);
  }, [currentPageIndex, pages]);

  const checkMoveToNextPage = () => {
    if (currentPage?.question && userAnswer && showAnswer) {
      return true;
    } else if (currentPage?.code && userCode && userCodeOutput.length > 0) {
      return true;
    } else if (!currentPage?.question && !currentPage?.code) {
      return true;
    }
    return false;
  };

  const moveToNextPage = async () => {
    if (currentPageIndex !== pages.length - 1) {
      setPageHistory([
        ...pageHistory,
        {
          pageId: currentPage?.id,
          ...currentPage,
          userAnswer,
          userCode,
          userCodeOutput,
          showAnswer,
          response,
          messages,
          setMessages,
          messagesCount,
          setMessagesCount,
        },
      ]);
      setMessages([]);
      setMessagesCount(0);
      if (currentPage?.question && (userAnswer || showAnswer)) {
        dispatch(incrementPageIndex(currentPageIndex));
        setUserAnswer("");
        setShowAnswer(false);
        axiosAuthInstance.post("usertracking/user-press-next-page/", {
          page_id: currentPage?.id,
        });
      } else if (currentPage?.code && userCode && userCodeOutput.length > 0) {
        dispatch(incrementPageIndex(currentPageIndex));
        setUserCode("");
        setUserCodeOutput("");
        axiosAuthInstance.post("usertracking/user-press-next-page/", {
          page_id: currentPage?.id,
        });
      } else if (!currentPage?.question && !currentPage?.code) {
        dispatch(incrementPageIndex(currentPageIndex));
        axiosAuthInstance.post("usertracking/user-press-next-page/", {
          page_id: currentPage?.id,
        });
      }
    } else {
      // Complete lesson
      await axiosAuthInstance.post("/learncs/complete-lesson/", {
        subtopic_name: currentSubtopic,
      });
      navigate("/home");
    }
  };

  return (
    <>
      <Box maxWidth="1600px" sx={{ marginX: "auto" }}>
        <Box
          maxWidth="1600px"
          sx={{
            position: "fixed",
            top: 0.5,
            bottom: 12,
            overflowY: "auto",
            width: "100%",
            zIndex: -1,
            scrollbarWidth: "none",
            backgroundColor: "#111210",
          }}
          ref={(container) => {
            if (container) {
              container.scrollTop = container.scrollHeight;
              container.style.scrollBehavior = "smooth";
            }
          }}
        >
          <Box position="sticky" top={0} zIndex={1000}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              width={"100%"}
              sx={{
                backgroundColor: "rgba(31, 32, 30, 0.3)",
                py: 1,
                backdropFilter: " blur(15px)",
              }}
            >
              <Typography sx={{ fontSize: 15, color: "#434842" }}>
                {title}
              </Typography>
              <Typography sx={{ fontSize: 15, color: "#434842" }}>
                &nbsp;
                {subtopic}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              backgroundColor: "#1F201E",
              py: 5,
              px: 5,
              my: 3,
              borderRadius: 2,
              mx: {
                md: "20%",
                sm: "12%",
                xs: "7%",
              },
              mb: 1,
            }}
          >
            <Typography sx={{ color: "primary.secondary" }}>
              {" "}
              {title}{" "}
            </Typography>
            <Typography sx={{ fontSize: 50, paddingTop: 2 }}>
              {subtopic}
            </Typography>
          </Box>
          <Box
            sx={{
              mx: {
                md: "20%",
                sm: "12%",
                xs: "7%",
              },
              mb: 1,
            }}
          >
            {loading ? (
              // Show the LoadingSkeletons component while loading is true
              <LoadingSkeletons />
            ) : (
              <>
                {pageHistory.map((page, index) => {
                  return (
                    <Box key={index}>
                      {page.question ? (
                        <LessonTypeThree
                          page={page.pageId}
                          question={page.question}
                          answer={page.answer}
                          showAnswer={page.showAnswer}
                          currentPage={page}
                          answerChoices={page.answer_choices}
                          isMultipleChoice={page.is_multiple_choice}
                          response={page.response}
                          userAnswer={page.userAnswer}
                          messages={page.messages}
                          messagesCount={page.messagesCount}
                          pageHistory={pageHistory}
                          setPageHistory={setPageHistory}
                          suggestion={page.followup_suggestion}
                        />
                      ) : page.code ? (
                        <LessonTypeTwo
                          page={page.pageId}
                          content={page.content}
                          userCode={page.userCode}
                          userCodeOutput={page.userCodeOutput}
                          image={page.image}
                          currentFileName={page.file_name}
                          messages={page.messages}
                          messagesCount={page.messagesCount}
                          pageHistory={pageHistory}
                          setPageHistory={setPageHistory}
                          suggestion={page.followup_suggestion}
                        />
                      ) : (
                        <LessonTypeOne
                          pageId={page.pageId}
                          content={page.content}
                          image={page.image}
                          messages={page.messages}
                          messagesCount={page.messagesCount}
                          pageHistory={pageHistory}
                          setPageHistory={setPageHistory}
                          suggestion={page.followup_suggestion}
                        />
                      )}
                    </Box>
                  );
                })}
                {pages[currentPageIndex] && (
                  <Box>
                    {pages[currentPageIndex]?.question ? (
                      <LessonTypeThree
                        question={pages[currentPageIndex]?.question}
                        answer={pages[currentPageIndex]?.answer}
                        setShowAnswer={setShowAnswer}
                        showAnswer={showAnswer}
                        currentPage={currentPage}
                        answerChoices={pages[currentPageIndex]?.answer_choices}
                        isMultipleChoice={
                          pages[currentPageIndex]?.is_multiple_choice
                        }
                        userAnswer={userAnswer}
                        setUserAnswer={setUserAnswer}
                        response={response}
                        setResponse={setResponse}
                        messages={messages}
                        setMessages={setMessages}
                        messagesCount={messagesCount}
                        setMessagesCount={setMessagesCount}
                        suggestion={
                          pages[currentPageIndex]?.followup_suggestion
                        }
                      />
                    ) : pages[currentPageIndex]?.code ? (
                      <LessonTypeTwo
                        content={pages[currentPageIndex]?.content}
                        originalCode={pages[currentPageIndex]?.code}
                        userCode={userCode}
                        setUserCode={setUserCode}
                        userCodeOutput={userCodeOutput}
                        setUserCodeOutput={setUserCodeOutput}
                        image={pages[currentPageIndex]?.image}
                        currentFileName={pages[currentPageIndex]?.file_name}
                        messages={messages}
                        setMessages={setMessages}
                        messagesCount={messagesCount}
                        setMessagesCount={setMessagesCount}
                        suggestion={
                          pages[currentPageIndex]?.followup_suggestion
                        }
                      />
                    ) : (
                      <LessonTypeOne
                        content={pages[currentPageIndex]?.content}
                        image={pages[currentPageIndex]?.image}
                        messages={messages}
                        setMessages={setMessages}
                        messagesCount={messagesCount}
                        setMessagesCount={setMessagesCount}
                        suggestion={
                          pages[currentPageIndex]?.followup_suggestion
                        }
                      />
                    )}
                  </Box>
                )}
              </>
            )}
          </Box>
          <Box
            maxWidth="1600px"
            display="flex"
            sx={{
              width: "100%",
              position: "fixed",
              bottom: 0,
              zIndex: 1001,
            }}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                backgroundColor: "rgba(31, 32, 30, 0.3)",
                py: 1.5,
                backdropFilter: " blur(15px)",
                width: "100%",
                borderTop: "solid 1px rgba(31, 32, 30, 0.9)",
                px: {
                  md: "10%",
                  sm: "5%",
                  xs: "5%",
                },
              }}
            >
              <LogoutRoundedIcon
                onClick={handleOpenLeaveLesson}
                sx={{
                  color: "#687066",
                  fontSize: 20,
                  mx: 1.5,
                  rotate: "180deg",
                  cursor: "pointer",
                  "&:hover": {
                    color: "#C33A3B",
                  },
                }}
              />
              <ProgressBar
                variant="determinate"
                value={10 + (currentPageIndex / (pages.length - 1)) * 90}
              />
              <Button
                onClick={moveToNextPage}
                disabled={!checkMoveToNextPage()}
                sx={{
                  backgroundColor: checkMoveToNextPage()
                    ? "#D4FF71"
                    : "#434842",
                  display: "flex",
                  minWidth: 35, // Override MUI's default minWidth
                  minHeight: 35,
                  padding: 0,
                  borderRadius: "50%",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  mx: 1.5,
                  "&:hover": {
                    bgcolor: "rgba(212, 255, 113, 0.8)",
                  },
                }}
              >
                <ArrowForwardOutlinedIcon
                  sx={{
                    color: "#111210",
                    fontSize: 18,
                  }}
                />
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <LeaveLessonModal
        openLeaveLesson={openLeaveLesson}
        handleCloseLeaveLesson={handleCloseLeaveLesson}
      />
    </>
  );
};

export default Lesson;
