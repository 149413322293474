import React from "react";
import { Box, Typography } from "@mui/material";

const EdsgerAILogo = ({ onClick }) => {
  return (
    <Box
      component="div"
      onClick={onClick}
      display="flex"
      flexDirection="row"
      alignItems="center"
      style={{ cursor: "pointer" }}
    >
      {/* Circle */}
      <Box
        sx={{
          width: "20px",
          height: "20px",
          borderRadius: 100,
          backgroundColor: "primary.main",
        }}
      ></Box>
      {/* Typography */}
      <Typography sx={{ paddingLeft: 1.5, fontSize: 20 }}>Edsger AI</Typography>
    </Box>
  );
};

export default EdsgerAILogo;
