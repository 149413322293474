import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosAuthInstance } from "../../utils/axiosInstance";

export const getHomepage = createAsyncThunk("tutor/getHomepage", async () => {
  const response = await axiosAuthInstance.get("/learncs/get-homepage/");
  return response.data;
});

export const getPagesBySubtopicName = createAsyncThunk(
  "tutor/getPagesBySubtopicName",
  async (subtopicName) => {
    const response = await axiosAuthInstance.post(
      "/learncs/get-pages-by-subtopic-name/",
      {
        subtopic_name: subtopicName,
      }
    );
    return { pages: response.data, subtopic: subtopicName };
  }
);

export const incrementPageIndex = createAsyncThunk(
  "tutor/incrementPageIndex",
  async (pageIndex) => {
    return pageIndex + 1;
  }
);

const initialState = {
  homepageLoading: false,
  homepageData: [],
  homepageError: null,
  subtopicLoading: false,
  pages: [],
  subtopicError: null,
  currentSubtopic: null,
  currentPageIndex: 0,
};

const tutorSlice = createSlice({
  name: "tutor",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getHomepage.pending, (state) => {
        state.homepageLoading = true;
      })
      .addCase(getHomepage.fulfilled, (state, action) => {
        state.homepageLoading = false;
        state.homepageData = action.payload;
        state.currentPageIndex = 0;
      })
      .addCase(getHomepage.rejected, (state) => {
        state.homepageError = true;
      })
      .addCase(getPagesBySubtopicName.pending, (state) => {
        state.subtopicLoading = true;
      })
      .addCase(getPagesBySubtopicName.fulfilled, (state, action) => {
        state.subtopicLoading = false;
        state.pages = action.payload.pages;
        state.currentSubtopic = action.payload.subtopic;
      })
      .addCase(getPagesBySubtopicName.rejected, (state) => {
        state.subtopicError = true;
      })
      .addCase(incrementPageIndex.fulfilled, (state, action) => {
        state.currentPageIndex = action.payload;
      });
  },
});

export default tutorSlice.reducer;
