import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";
import storage from "redux-persist/lib/storage";
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import authReducer from "./auth/authSlice";
import tutorSlice from "./tutor/tutorSlice";

const authPersistConfig = {
  key: "auth",
  storage: storage,
  whitelist: ["user", "isLoggedIn"],
};

const tutorPersistConfig = {
  key: "tutor",
  storage: storage,
  whitelist: ["currentSubtopic"],
};

const reducers = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  tutor: persistReducer(tutorPersistConfig, tutorSlice),
});

let store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([thunk]),
});
let persistor = persistStore(store);

export { store, persistor };
