import { Box, Typography } from "@mui/material";
import React from "react";
import LinkedIn from "../../../assets/icons/linkedin.svg";
import Twitter from "../../../assets/icons/twitter.svg";

const TitleTypography = ({ value }) => {
  return (
    <Typography sx={{ color: "#687066", py: 1, fontSize: 13 }}>
      {value}
    </Typography>
  );
};

const DescriptionTypography = ({ value }) => {
  return <Typography sx={{ pb: 0.8, fontSize: 13 }}>{value}</Typography>;
};

const Footer = () => {
  return (
    <Box
      sx={{
        pt: { md: 20, sm: 5, xs: 4 },
        pb: 10,
        mx: { md: 20, sm: 5, xs: 2 },
      }}
    >
      <Box
        display="flex"
        flexDirection={{ md: "row", xs: "column" }}
        justifyContent="space-between"
      >
        {/* Curriculum */}
        <Box sx={{ py: 2 }}>
          <TitleTypography value={"Curriculum"} />
          <DescriptionTypography value={"C (Programming Language)"} />
          <DescriptionTypography value={"Advanced Programming"} />
          <DescriptionTypography value={"Data Structures and Algorithms"} />
          <DescriptionTypography value={"Discrete Mathematics"} />
          <DescriptionTypography value={"Computer Architecture"} />
          <DescriptionTypography value={"Computer Networking"} />
          <DescriptionTypography value={"Databases"} />
          <DescriptionTypography value={"Operating Systems"} />
          <DescriptionTypography value={"Distributed Systems"} />
          <DescriptionTypography value={"Languages and Compilers"} />
        </Box>
        {/* Features */}
        <Box sx={{ py: 2 }}>
          <TitleTypography value={"Features"} />
          <DescriptionTypography value={"Beyond Syntax"} />
          <DescriptionTypography value={"Active Learning"} />
          <DescriptionTypography value={"1-to-1 Guidance"} />
        </Box>
        {/* Pricing */}
        <Box sx={{ py: 2 }}>
          <TitleTypography value={"Pricing"} />
          <DescriptionTypography value={"Free"} />
        </Box>
        {/* Terms and Conditions */}
        <Box sx={{ py: 2 }}>
          <TitleTypography value={"Terms and Conditions"} />
          <DescriptionTypography value={"Terms of Use"} />
          <DescriptionTypography value={"Privacy Policy"} />
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        sx={{ pt: 15 }}
      >
        <Typography> EDSGER AI © 2024 </Typography>
        <Box display="flex" flexDirection="row">
          <a
            href="https://my.linkedin.com/company/synapselearning"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={LinkedIn}
              alt="LinkedIn"
              width={24}
              style={{ marginRight: 10 }}
            />
          </a>
          <a
            href="https://x.com/synapse_tutor"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Twitter}
              alt="Twitter"
              width={24}
              style={{ marginRight: 10 }}
            />
          </a>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
