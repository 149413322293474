import React from "react";
import { Box, Modal, Typography } from "@mui/material";
import BrowserIcon from "../../assets/icons/browser_size.svg";
import SynapseLogo from "../../assets/icons/synapse-logo.svg";
import { useNavigate } from "react-router";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  p: 4,
  outline: "none",
};

const BrowserSizeWarning = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = React.useState(false);

  React.useEffect(() => {
    const handleResize = () => {
      const minWidthThreshold = 1000;

      if (window.innerWidth >= minWidthThreshold && showModal) {
        setShowModal(false);
      } else if (window.innerWidth < minWidthThreshold && !showModal) {
        setShowModal(true);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [showModal]);

  const handleLogoClicked = () => {
    navigate("/");
  };

  return (
    <>
      <Modal
        open={showModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          backdropFilter: "blur(100px)",
          WebkitBackdropFilter: "blur(100px)",
        }}
      >
        <Box
          sx={style}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <img src={BrowserIcon} width="150px" alt="BrowserIcon" />
          <Typography
            id="modal-modal-title"
            sx={{ mt: 5, textAlign: "center" }}
          >
            Your browser is too small for our content.
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, mb: 15, textAlign: "center" }}
          >
            Please resize your window to view the content properly.
          </Typography>
          <Box onClick={handleLogoClicked} sx={{ cursor: "pointer" }}>
            <img src={SynapseLogo} width="180px" alt="SynapseLogo" />
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default BrowserSizeWarning;
