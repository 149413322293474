import React, { useCallback, useEffect, useState } from "react";
import Navbar from "../../../components/navbar";
import { Box, Typography } from "@mui/material";
import GoogleIcon from "../../../assets/icons/google_logo.svg";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../redux/auth/authSlice";
import BrowserSizeWarning from "../../../components/browser-warning";
import TextInput from "../../../common/text-input";
import CustomButton from "../../../common/button";

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const auth = useSelector((state) => state.auth);
  const { isLoggedIn } = auth;

  const handleSignUpButton = () => {
    navigate("/signup");
  };

  const openGoogleLoginPage = useCallback(() => {
    const googleAuthUrl = "https://accounts.google.com/o/oauth2/v2/auth";
    const scope = [
      "https://www.googleapis.com/auth/userinfo.profile",
      "https://www.googleapis.com/auth/userinfo.email",
    ].join(" ");
    const params = new URLSearchParams({
      response_type: "code",
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      redirect_uri: process.env.REACT_APP_GOOGLE_REDIRECT_URI,
      prompt: "select_account",
      access_type: "offline",
      scope,
    });
    const url = `${googleAuthUrl}?${params}`;
    window.location.href = url;
  }, []);

  const handleLogin = () => {
    dispatch(login({ email, password, navigate }));
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/home");
    }
  }, [isLoggedIn, navigate]);

  return (
    <Box sx={{ height: "100vh", backgroundColor: "background.default" }}>
      <BrowserSizeWarning />
      <Navbar />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{ py: 10 }}
      >
        <Box>
          {/* Header */}
          <Box sx={{ pb: 3 }}>
            <Typography sx={{ fontWeight: 800, fontSize: 20 }}>
              Login
            </Typography>
            <Typography display="flex" flexDirection="row" sx={{ py: 1 }}>
              Don’t have an account?
              <Typography
                onClick={handleSignUpButton}
                component="span"
                sx={{
                  color: "primary.main",
                  fontWeight: 800,
                  cursor: "pointer",
                  "&:hover": {
                    color: "rgba(212, 255, 113, 0.8)",
                  },
                }}
              >
                &nbsp;Sign up
              </Typography>
            </Typography>
          </Box>
          {/* Text Inputs */}
          <Box display="flex" flexDirection="column">
            <TextInput
              id="email"
              label="Email"
              type="text"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <TextInput
              id="password"
              label="Password"
              type="password"
              placeholder="Password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              value={password}
            />
          </Box>
          {/* Buttons */}
          <Box display="flex" flexDirection="column" alignItems="center">
            <CustomButton
              type="primary"
              onClick={handleLogin}
              value={"Login with email"}
            />
            <Box display="flex" alignItems="center" width="100%">
              <Box flex="1" borderBottom="1px solid #1F201E" />
              <Typography mx={1}>Or</Typography>
              <Box flex="1" borderBottom="1px solid #1F201E" />
            </Box>
            <CustomButton
              type="with-icon"
              onClick={openGoogleLoginPage}
              src={GoogleIcon}
              value={"Login with Google"}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginPage;
