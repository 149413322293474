import { useEffect, useState } from "react";
import "./index.css";
import PrismJS from "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-python";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-java";

const CodeSnippet = ({ code, language }) => {
  const [copyStatus, setCopyStatus] = useState("Copy code");

  useEffect(() => {
    PrismJS.highlightAll();
  }, [code, language]);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(code);
      setCopyStatus("Copied!");
      setTimeout(() => setCopyStatus("Copy code"), 2000);
    } catch (err) {
      setCopyStatus("Failed to copy");
      setTimeout(() => setCopyStatus("Copy code"), 2000);
    }
  };

  return (
    <div style={{ position: "relative", marginBottom: "20px" }}>
      <button
        onClick={handleCopy}
        style={{
          fontFamily: "inherit",
          position: "absolute",
          right: "10px",
          top: "10px",
          background: "#D4FF71",
          color: "#1E1E1E",
          border: "none",
          borderRadius: "5px",
          padding: "5px 10px",
          cursor: "pointer",
          zIndex: 1,
          fontSize: 13,
        }}
      >
        {copyStatus}
      </button>
      <pre
        style={{
          background: "#1E1E1E",
          borderRadius: "10px",
          position: "relative",
          padding: "20px",
          overflow: "auto",
          fontSize: 13,
        }}
      >
        <code className={`language-${language}`}>{code}</code>
      </pre>
    </div>
  );
};

export default CodeSnippet;
