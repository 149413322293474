import { Box, Typography, Button } from "@mui/material";
import React from "react";
import PricingBox from "./pricing-box";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import { useNavigate } from "react-router";

const Pricing = () => {
  const navigate = useNavigate();
  const handleTryForFreeButton = () => {
    navigate("/signup");
  };
  return (
    <Box
      id="Pricing"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{ px: 10, py: 20 }}
    >
      <Typography sx={{ fontSize: 40, textAlign: "center", mb: 10 }}>
        Pricing
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", sm: "row", xs: "column" },
        }}
      >
        <PricingBox
          title={"Free"}
          price={"0"}
          button={"Try for Free"}
          button_type={"secondary"}
          onClick={handleTryForFreeButton}
          description={
            <>
              <Typography
                display="flex"
                flexDirection="row"
                alignItems="center"
                sx={{ pb: 2, fontSize: 16 }}
              >
                <DoneRoundedIcon
                  sx={{ pr: 2, fontSize: 18, color: "#E3F7BA" }}
                />
                Full access to 10 lessons across all 10 subjects
              </Typography>
            </>
          }
        />
        <PricingBox
          title={"Pro"}
          price={"49"}
          button={"Coming soon"}
          button_type={"no-bg"}
          txtcolor={"primary.secondary"}
          description={
            <>
              <Typography
                display="flex"
                flexDirection="row"
                alignItems="center"
                sx={{ pb: 2, fontSize: 16 }}
              >
                <DoneRoundedIcon
                  sx={{ pr: 2, fontSize: 18, color: "#E3F7BA" }}
                />
                Full access to ALL 2000+ lessons across all 10 subjects
              </Typography>
            </>
          }
        />
      </Box>
    </Box>
  );
};

export default Pricing;
