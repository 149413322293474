import { Box } from "@mui/material";
import React from "react";
import Navbar from "../../components/navbar";
import Features from "./3_features";
import Pricing from "./4_pricing";
import StartLearning from "./5_start-learn";
import Footer from "./6_footer";
import CustomButton from "../../common/button";
import Curriculums from "./2_curriculum";
import MainHeader from "./1_header";

const LandingPage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "background.default",
      }}
    >
      <Navbar />
      <MainHeader />
      <Curriculums />
      <Features />
      <Pricing />
      <StartLearning />
      <Footer />
    </Box>
  );
};

export default LandingPage;
