import React from "react";
import "./index.css";
import { Box } from "@mui/material";

const LoadingAnimation = () => {
  return (
    <Box sx={{ height: "100vh" }} display="flex" alignItems="center">
      <Box
        className="typing-animation"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="row"
      >
        <Box className="dot dot1"></Box>
        <Box className="dot dot2"></Box>
        <Box className="dot dot3"></Box>
      </Box>
    </Box>
  );
};

export default LoadingAnimation;
