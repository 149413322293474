import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CodeEditor from "../../../../components/code-editor";
import AskTutor from "../../../../components/ask-tutor";
import ReactMarkdown from "react-markdown";
import MarkdownRenderer from "../../../../components/markdown-renderer";

const LessonTypeTwo = ({
  image,
  content,
  userCode,
  setUserCode,
  originalCode,
  userCodeOutput,
  setUserCodeOutput,
  currentFileName,
  messages,
  setMessages,
  messagesCount,
  setMessagesCount,
  pageHistory,
  setPageHistory,
  pageId,
  suggestion,
}) => {
  const [imageWidth, setImageWidth] = useState("40%");

  useEffect(() => {
    const img = new Image();
    img.src = image;
    img.onload = () => {
      if (img.width > img.height) {
        setImageWidth("65%");
      } else {
        setImageWidth("35%");
      }
    };
  }, [image]);

  return (
    <Box sx={{ my: 10 }}>
      <Typography component="div">
        {image && (
          <img
            src={image}
            alt="page"
            style={{
              width: imageWidth,
              height: "auto",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "2rem",
            }}
          />
        )}
        <MarkdownRenderer content={content} />
      </Typography>
      <CodeEditor
        userCode={userCode}
        setUserCode={setUserCode}
        originalCode={originalCode}
        userCodeOutput={userCodeOutput}
        setUserCodeOutput={setUserCodeOutput}
        currentFileName={currentFileName}
      />
      <AskTutor
        messages={messages}
        setMessages={setMessages}
        messagesCount={messagesCount}
        setMessagesCount={setMessagesCount}
        pageHistory={pageHistory}
        setPageHistory={setPageHistory}
        pageId={pageId}
        suggestion={suggestion}
      />
    </Box>
  );
};

export default LessonTypeTwo;
