import React from "react";
import { Box, Typography, Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import CustomButton from "../../common/button";
import ProgressBar from "../progress-bar";

const style = {
  position: "absolute",
  top: "50%",
  left: "65%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "rgba(31, 32, 30, 0.5)",
  px: 3,
  pt: 4,
  borderRadius: 3,
  width: 310,
  outline: "none",
  backdropFilter: "blur(10px)",
};

const StartLessonModal = ({
  openSubtopic,
  handleCloseSubtopic,
  handleStartLesson,
  modalContentSubtopic,
}) => {
  return (
    <Modal
      open={openSubtopic}
      onClose={handleCloseSubtopic}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
          sx={{ height: 420 }}
        >
          {/* Titles */}
          <Box>
            <Typography sx={{ fontSize: 13, color: "primary.secondary" }}>
              {modalContentSubtopic.title}
            </Typography>
            <Typography sx={{ fontSize: 20, pt: 2 }}>
              {modalContentSubtopic.subtopic}
            </Typography>
          </Box>
          {/* Progress */}
          <Box sx={{ width: "100%" }}>
            <Box display="flex" justifyContent="space-between" sx={{ mb: 1.5 }}>
              <Typography
                id="modal-modal-description"
                sx={{
                  color:
                    modalContentSubtopic.status === "In Progress"
                      ? "#E3F7BA"
                      : modalContentSubtopic.status === "Completed"
                      ? "#449C3D"
                      : "#30322F",
                }}
              >
                {modalContentSubtopic.status}
              </Typography>
              <Typography
                sx={{
                  color:
                    modalContentSubtopic.status === "In Progress"
                      ? "#E3F7BA"
                      : modalContentSubtopic.status === "Completed"
                      ? "#449C3D"
                      : "#30322F",
                }}
              >
                0%
              </Typography>
            </Box>
            <ProgressBar
              type={"secondary"}
              value={modalContentSubtopic.status === "Completed" ? 100 : 0}
              variant="determinate"
            />
          </Box>
          {/* Buttons */}
          <Box display="flex" flexDirection="column" alignItems="center">
            {modalContentSubtopic.status === "In Progress" ? (
              <CustomButton
                onClick={handleStartLesson}
                type={"secondary"}
                value={"Start Lesson"}
              />
            ) : (
              <CustomButton
                onClick={handleStartLesson}
                value={"Start Lesson"}
                type={"secondary"}
                disabled={"disabled"}
              />
            )}
            <Box sx={{ mt: 1.5, mb: 1 }}>
              <CustomButton
                value={"Back"}
                type={"no-bg"}
                txtcolor={"#434842"}
                onClick={handleCloseSubtopic}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default StartLessonModal;
