import React, { useCallback, useRef, useEffect, useState } from "react";
import ForceGraph2D from "react-force-graph-2d";
import { Box } from "@mui/material";

const BackgroundNodes = () => {
  const fgRef = useRef();
  const containerRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const generateGraph = useCallback(() => {
    const N = 18; // Number of nodes
    const nodes = [...Array(N).keys()].map((i) => ({
      id: i,
      x: 300 * Math.cos((2 * Math.PI * i) / N),
      y: 300 * Math.sin((2 * Math.PI * i) / N),
    }));
    const links = [];

    // Create a more densely connected graph
    for (let i = 0; i < N; i++) {
      const numConnections = 2;
      for (let j = 0; j < numConnections; j++) {
        let target;
        do {
          target = Math.floor(Math.random() * N);
        } while (
          target === i ||
          links.some(
            (link) =>
              (link.source === i && link.target === target) ||
              (link.source === target && link.target === i)
          )
        );
        links.push({ source: i, target });
      }
    }

    return { nodes, links };
  }, []);

  useEffect(() => {
    if (fgRef.current) {
      const fg = fgRef.current;
      fg.d3Force("link").distance(() => Math.random() * 220);
      fg.d3Force("charge").strength(-250);
      //   fg.d3Force("center", d3.forceCenter(0, 0));
    }
  }, []);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries[0]) {
        const { width, height } = entries[0].contentRect;
        setDimensions({ width, height });
      }
    });
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }
    return () => resizeObserver.disconnect();
  }, []);

  return (
    <Box
      ref={containerRef}
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
    >
      <ForceGraph2D
        ref={fgRef}
        graphData={generateGraph()}
        width={dimensions.width}
        height={dimensions.height}
        backgroundColor="#111210"
        nodeColor={(node) => "#1F201E"}
        linkColor={(link) => "#1F201E"}
        linkWidth={() => 3}
        nodeRelSize={8}
        // d3VelocityDecay={0.001}
      />
    </Box>
  );
};
export default BackgroundNodes;
