import React from "react";
import { Box, Typography } from "@mui/material";
import CustomButton from "../../../common/button";
import { useNavigate } from "react-router";

const MainHeader = () => {
  const navigate = useNavigate();
  const handleTryForFreeButton = () => {
    navigate("/signup");
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        flexGrow: 1,
        pt: 15,
        px: { md: "inherit", sm: "inherit", xs: 2 },
      }}
    >
      <Typography
        sx={{
          fontSize: { md: 60, sm: 50, xs: 40 },
          textAlign: "center",
          color: "#ECEEEC",
        }}
      >
        Become a Better <br />
        Software Engineer
      </Typography>
      <Typography
        sx={{
          py: 5,
          color: "#ECEEEC",
          textAlign: "center",
          fontSize: { md: 18, sm: 17, xs: 16 },
          px: { md: 35, sm: 10, xs: 1 },
        }}
      >
        AI tutor designed for software engineers ready to dive deep into
        <span style={{ color: "#E3F7BA" }}>
          {" "}
          low-level computer science concepts
        </span>{" "}
        beyond syntax, frameworks and interview-prep.
      </Typography>
      <CustomButton
        value={"Get Started"}
        onClick={handleTryForFreeButton}
        type={"secondary"}
      />
      <Typography sx={{ color: "#434842", mt: 4 }}>
        No credit card required
      </Typography>
    </Box>
  );
};

export default MainHeader;
