import React from "react";
import { Button } from "@mui/material";

const CustomButton = ({
  onClick,
  value,
  type,
  src,
  alt,
  disabled,
  txtcolor,
  hovercolor,
}) => {
  return (
    <Button
      sx={{
        bgcolor:
          type === "primary"
            ? "primary.main"
            : type === "with-icon"
            ? "primary.white"
            : type === "no-bg"
            ? "transparent"
            : disabled
            ? "#434842"
            : "primary.main",
        color: type === "no-bg" ? txtcolor : "#000",
        py: type === "secondary" ? 1 : type === "navbar" ? 0.5 : 1.5,
        px: 4,
        fontSize: 15,
        mt:
          type === "secondary"
            ? 0
            : type === "navbar"
            ? 0
            : type === "no-bg"
            ? 0
            : 5,
        mb:
          type === "secondary"
            ? 0
            : type === "navbar"
            ? 0
            : type === "no-bg"
            ? 0
            : 5,
        "&:hover": {
          color: type === "no-bg" ? "primary.main" : "inherit",
          bgcolor:
            type === "with-icon"
              ? "rgba(255, 255, 255, 0.8)"
              : type === "no-bg"
              ? "transparent"
              : "rgba(212, 255, 113, 0.8)",
        },
        borderRadius: 100,
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {type === "with-icon" && (
        <>
          <img
            src={src}
            alt={alt}
            style={{ width: "20px", height: "20px", marginRight: "10px" }}
          />
        </>
      )}
      {value}
    </Button>
  );
};

export default CustomButton;
